<template>
    <div class="page bg-white">
        <el-table :data="list"
                  v-loading="loading"
                  size="small"
                  height="calc(100vh - 200px)" class="table">
            <el-table-column prop="ticketingServerImage" label="服务图片" width="110">
                <template slot-scope="scope">
                    <el-image class="infoImg" :src="scope.row.ticketingServerImage[0]" fit="contain">
                        <el-image slot="placeholder" fit="contain" class="infoImg" :src="require('../../../../assets/img/zhanwei.png')"></el-image>
                        <el-image slot="error" fit="contain" class="infoImg" :src="require('../../../../assets/img/zhanwei.png')"></el-image>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="ticketingServerName" label="服务名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="ticketingServerSkuPrice" label="价格" show-overflow-tooltip></el-table-column>
            <el-table-column prop="specifications" label="服务规格" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div class="text_left" v-if="scope.row.specifications.numberOfPeople[0]">人数：{{scope.row.specifications.numberOfPeople.join('/')}}</div>
                    <div class="text_left" v-if="scope.row.specifications.age[0]">年龄：{{scope.row.specifications.age.join('/')}}</div>
                    <div class="text_left" v-if="scope.row.specifications.language[0]">语言：{{scope.row.specifications.language.join('/')}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="ticketingServerState" label="状态">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.ticketingServerState"
                               @change="statusChange(scope.row)"
                               :active-value="'0'"
                               :inactive-value="'1'"
                    ></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="createUser" label="添加人" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createTime" label="添加时间" sortable show-overflow-tooltip></el-table-column>
            <el-table-column prop="updateTime" label="最后修改时间" sortable show-overflow-tooltip></el-table-column>
            <el-table-column fixed="right" width="150" label="操作">
                <template slot-scope="scope">
                    <el-button v-if="hasPermission('service:list:view')" type="text" size="mini"
                               @click="viewEdit(scope.row, 0)">查看
                    </el-button>
                    <el-button v-if="hasPermission('service:list:edit')" type="text" size="mini"
                               @click="viewEdit(scope.row, 1)">编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!--查看、编辑-->
        <ViewService ref="viewService" @refreshDataList="queryData"></ViewService>
    </div>
</template>

<script>
    import ViewService from './viewService'

    export default {
        components: {ViewService},
        data() {
            return {
                list: [],
                current: 1,
                size: 10,
                total: 0,
                loading: false,
            }
        },
        mounted() {
            this.queryData()
        },
        methods: {
            // 数据查询
            queryData() {
                this.$axios(this.api.order.sysGetTicketingServer, {current: this.current, size: this.size}).then((res) => {
                    if (res.status) {
                        res.data.records.map(item => {
                            item.specifications = {
                                age: item.age,
                                language: item.language,
                                numberOfPeople: item.numberOfPeople,
                            }
                        })
                        this.list = res.data.records
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            // 状态修改
            statusChange(row) {
                let {id, ticketingServerState} = row
                this.$axios(this.api.order.updateTicketingServerState, {ticketingServerId: id, ticketingServerState}).then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功');
                        this.queryData();
                    } else {
                        this.$message.error('操作失败');
                        this.queryData();
                    }
                })
            },
            // 查看、编辑
            viewEdit(row, type) {
                this.$refs.viewService.init(row.id, type);
            },
            // 每页数
            sizeChangeHandle(val) {
                this.size = val
                this.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.current = val
                this.queryData()
            },
        }
    }
</script>

<style scoped>
    .infoImg {
        width: 100px;
        height: 100px;
    }
</style>
